import React from "react";
import LandingButton from "./LandingButton";
import InstagramLoop from "./InstagramLoop";

const Landing = () => {
    return (
        <div className="landing-container">
            <InstagramLoop />
            <h3> The MyNext4 team has decided to move on from our LLC; The sign-in, course, college, and career search features are all unusable as of December 12, 2023. </h3>
    
            {/* <LandingButton /> */}
        </div>
    )
}

export default Landing;
